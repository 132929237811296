import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Layout from "../layout";
import Services from "../components/Services/Services";
import config from "../../data/SiteConfig";
import ServicesData from "../../data/AdminServices.json";

class ServicesPage extends Component {
  render() {
    return (
      <Layout>
        <Helmet title={`Hizmetlerimiz| ${config.siteTitle}`} />
        <div className="about-container">
          <div className="page" style={{ height: "auto" }}>
            <section className="page-image-section">
              <div className="page-image-wrapper">
                <img
                  className="page-image"
                  alt="Hizmetlerimiz"
                  src={ServicesData.image}
                />
                <h1 className="page-title">Hizmetlerimiz</h1>
              </div>
            </section>
            <Services />
          </div>
        </div>
      </Layout>
    );
  }
}

export default ServicesPage;
